import React, { useState, useEffect} from "react";
import '../App';
import CallIcon from '@material-ui/icons/Call';
import CloseIcon from '@material-ui/icons/Close';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CallerTuneFile from "../ringing.mp3";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CallHangup from "../call_hangup.png";
import CallReceive from "../call_pickup.png";
import Mute from "../mute.png";
import Unmute from "../unmute.png";
import { WSS_URL } from '../constant';
import Logout from "../images.png";

import { Invitation, Inviter, InviterOptions, Unregister, Registerer, Session, SessionState, RegistererState, UserAgent, UserAgentOptions, InvitationAcceptOptions } from "sip.js";

let userAgent: any;

//	Interval variable
let durationInterval: any;

let incomingMediaStream, outgoingMediaStream: Session;

//	Incoming and outgoing sessions
var incomingSession, outgoingSession: Session;

const Webphone=()=>{
    
var btn = document.querySelectorAll(".col-3 button");


  const avatarStyle={backgroundColor:'#303f9f',margin:'20px',padding:'30px',marginLeft:'150px'}
  const [callDirection, setCallDirection] = useState('');
  const [state = {digitKps: '', callScreenKps: ''}, setStateKps] = useState([]);
  const [statescreen = {digit: '', callScreen: ''}, setStatescreen] = useState([]);
  const [stat , setStat] = useState([]);
  const [number, setNumber] = useState('');
  const [receiveCallBtn, setReceiveCallBtn] = useState(false);
  const [hangupCallBtn, setHangupCallBtn] = useState(false);
  const [muteCallBtn, setMuteCallBtn] = useState(false);
  const [callTransferType, setCallTransferType ] =  useState('Blind');
  //const history = useHistory();

  //	Call duration state
  const [seconds, setSeconds ] =  useState(0);
  const [minutes, setMinutes ] = useState(0);

  const [isMuted, setIsMuted] = useState(false);

  const callerTuneplay = new Audio(CallerTuneFile);
  const sendDTMFKps = (event) => {
		callStatusCardProperties("sendDTMFKps", event.target.innerHTML.toString());	//	Send DTMF
  }

  useEffect(() => {

	userAgentRegistration();
	
	var authenticate = localStorage.getItem("authenticated");
	var url = window.location.href;
	const answer_array = url.split('=');

	//alert(answer_array[1]);
	if(answer_array[1] !== 'undefined'){
		//alert(answer_array[1]);
		var destination = answer_array[1];
		setStateKps({
			digitKps: destination
		});
		state.digitKps = destination;
		//directdial();
		//callStatusCardProperties("dialNumberKps", answer_array[1]);	//	Initiate the outgoing call
	}
	//alert(authenticate);
	if(authenticate === null || authenticate === 'false' || authenticate === ''){
		window.location.href = '/login';
	}
	getLocalStream();

	//userAgentsubscribe();
}, []);
 
const directdial = () => {
	callStatusCardProperties("dialNumberKps");
}

function getLocalStream() {
    navigator.mediaDevices.getUserMedia({video: false, audio: true}).then((stream) => {
        //window.localStream = stream;
        //window.localAudio.srcObject = stream;
        window.localAudio.autoplay = true;
    }).catch((err) => {
        console.error(`you got an error: ${err}`)
    });
}

const userAgentRegistration = () => {
	

	//	Create user agent object
	var username = localStorage.getItem("username");
	var password = localStorage.getItem("password");
	var domain = localStorage.getItem("domain");
	var UAURI = UserAgent.makeURI("sip:" + username + "@" + domain);
	if (!UAURI) {
		throw new Error("Failed to create UserAgent URI ....");
	}
	

	const userOptions: UserAgentOptions = {
		uri: UAURI,
		authorizationPassword: password,
		authorizationUsername: username,
		transportOptions: {
			server: WSS_URL,
			traceSip: true,
		},
		delegate: { onInvite },
		register: true,
		noAnswerTimeout: 60,
		userAgentString: "ASTPP | WEBRTC ",
		//viaHost : '1.1.1.1',
		//contactName : '2.2.2.2',
		dtmfType: "info",
		displayName: "WEBRTC",
		activeAfterTransfer: false, //	Die when the transfer is completed
		logBuiltinEnabled: true, //	Boolean - true or false - If true throws console logs
	};
	console.log(userOptions);
	userAgent = new UserAgent(userOptions);

	console.log("USSSSSEEERRR AGENT");
	console.log(userAgent);
	//	User agent start and registration

	userAgent.start().then(() => {
		console.log("Connected ....");

		//	Create register object
		const registerer = new Registerer(userAgent);
		registerer.stateChange.addListener(
			(registrationState: RegistererState) => {
				console.log("registrationState");
		});
		registerer.stateChange.addListener(
			(registrationState: RegistererState) => {
				console.log("registrationState => ", registrationState);

				switch (registrationState) {
					case RegistererState.Registered:
						console.log("Registered11111 ....");
						localStorage.setItem("logout_count", '');

						break;
					case RegistererState.Unregistered:
						console.log("Unregistered ....");
						//alert(456);
						let start = 1;
						let count = localStorage.getItem("logout_count");
						//alert (count);
						if(count === ''){
							localStorage.setItem("logout_count", start);
						}else{
							  count = 2;
							  localStorage.setItem("logout_count", count);
						}
						//localStorage.setItem("logout_count", count);
						count = localStorage.getItem("logout_count");
						if(count === '2'){
							//alert("coming");
							localStorage.setItem("logout_count", '');
							localStorage.setItem("authenticated", false);
							localStorage.setItem("logout", true);
							window.location.href = '/login';
							break;
						}
						//if()
						// if (count === ''){
						// 	var count = start;
						// }else{
						// 	var remain = localStorage.setItem("logout_count");
						// 	count = remain + 1;
						// }
						// alert(count);
						//localStorage.setItem("logout_count", count);
						//alert(456);
						//logoutwebphone();
						break;
					case RegistererState.Terminated:
						alert(789);
						console.log("Terminated ....");
						//logoutwebphone();
						break;
					default:
						alert(111);
						console.log("Could not identified registration state .... ", registrationState);
						//logoutwebphone();
						break;
				}
			}
		);
		if (state.callScreen !== "true") {
			registerer.register().then((request) => {
				// console.log("Successfully sent REGISTER request .... ", request);
			}).catch((error) => {
				// console.log("Failed to send REGISTER request .... ", error);
			});
		}
	}).catch((error) => {
		console.log("Failed to connect user agent .... ", error);
	});

}
	// Call media element control media on the DOM
	const mediaElement = document.getElementById("mediaElement");

  function callStatusCardProperties (functionName = '', argumentOne = '', argumentTwo = '') {
	var domain = localStorage.getItem("domain");
	if(functionName !== '') {
		var dataForLocalStorageKps = {functionName: functionName, argumentOne: argumentOne, argumentTwo: argumentTwo};
		console.log("dataForLocalStorageKps ==> ");
		console.log(dataForLocalStorageKps);
		console.log(functionName);
		console.log(state.digitKps);


		//return true;
		switch(functionName) {
			case "dialNumberKps":
				setStateKps({
					callScreenKps: 'true',	//	Hide call screen
					digitKps: `${state.digitKps}`
				});
				//const callInitiate = () => {
					//	Set target
					// const target = UserAgent.makeURI( "sip:" + state.digit + "@" + usercred.domain );

					// var UAURI = UserAgent.makeURI("sip:10014@mobile364.ee.astppbilling.org:7777");
					// const userOptions: UserAgentOptions = {
					// 	uri: UAURI,
					// 	authorizationPassword: "Abcd@789",
					// 	authorizationUsername: "10014",
					// 	transportOptions: {
					// 		server: "wss://eewss.astppbilling.org:7443",
					// 		traceSip: true,
					// 	},
					// 	register: true,
					// 	noAnswerTimeout: 60,
					// 	userAgentString: "ASTPP | WEBRTC | CONEXO",
					// 	dtmfType: "info",
					// 	displayName: "CONEXO - WEBRTC",
					// 	activeAfterTransfer: false, //	Die when the transfer is completed
					// 	logBuiltinEnabled: false, //	Boolean - true or false - If true throws console logs
					// };
					// console.log(userOptions);
					// userAgent = new UserAgent(userOptions);


					//const target = UserAgent.makeURI( "sip:10008@mobile364.ee.astppbilling.org:7777" );
					const target = UserAgent.makeURI("sip:" + state.digitKps + "@" + domain);
					const inviter = new Inviter(userAgent, target);
			
					console.log("INVITER");
					console.log(inviter);
					
					
					// Setup outgoing session delegate
					inviter.delegate = {
						// Handle outgoing REFER request.
						onRefer(referral: Referral) {
							//console.log("Handle outgoing REFER request.");
							referral.accept().then(() => {
								referral.makeInviter().invite();
							});
						},
					};
			
					setStatescreen({
						callScreen: "true", //	Show call screen
						digit: `${state.digitKps}`
						
					});
					setCallDirection("Calling to"); //	Set call direction
			
					//	Intialise outgoing session
					outgoingSession = inviter;
			
					//////////////////////////////////////////
					// //	Keypad data array
					// var keypadDataParams = {
					// 	callScreen: "true",
					// 	digit: state.digit,
					// 	receiveCallBtn: false, //	Mange receive button
					// 	muteCallBtn: true, //	Mange mute button
					// 	transferCallBtn: true, //	Mange transfer button
					// 	mergeCallBtn: true, //	Mange merge button
					// 	callType: "Outgoing", //	Mange call type
					// 	backUrl: backUrl, //	Mange back url
					// 	incomingSession: "", //	Mange incomingSession
					// 	outgoingSession: outgoingSession, //	Mange outgoingSession
					// }
					// //	Send update keypad data
					// props.updateKeypadData(keypadDataParams);
					//////////////////////////////////////////
			
					// Setup session state change handler
					inviter.stateChange.addListener((callingState: SessionState) => {
						console.log("callingState ==> ", callingState);

						// inviter.SessionState.on("rejected", function(){

						// 	alert("rejected was triggered");
			
						// });

						switch (callingState) {
							
							case SessionState.Establishing:
								console.log("Ringing on destination ....");
								setStatescreen({
									callScreen: "true", //	Show call screen
									digit: `${state.digitKps}`
								});
								setCallDirection("Calling to"); //	Set call direction
								setHangupCallBtn(true); //	Mange hangup button
								setReceiveCallBtn(false); //	Mange receive button
								callerTuneplay.play(); //	Caller tune play
								break;
							case SessionState.Established:
								console.log("Call answered ....");
								console.log(inviter);
								//alert("666");
								// console.log("Call incomingSession OUT....");
								// console.log(incomingSession);

								// console.log("Call outgoingSession OUT....");
								// console.log(outgoingSession);
								
								
								setCallDirection("Talking to"); //	Set call direction
								setupRemoteMedia(inviter); //	Media audio control
								callerTuneplay.pause(); //	Caller tune pause
								setMuteCallBtn(true); //	Mange mute button
								callDuration(true); //	Call duration
								setHangupCallBtn(true); //	Mange hangup button
								setReceiveCallBtn(false); //	Mange receive button
								callKeypadSiderProperties('callEstablished', 'Established');	//	On answer manage keypadsider states
								break;
							case SessionState.Terminated:
								console.log("Call terminated ....");
								//alert("222");
								setStatescreen({
									callScreen: "", //	Hide call screen
									digit: `${state.digitKps}`
								});
								setCallDirection(""); //	Set call direction
								cleanupMedia(); //	Stop media audio control
								callerTuneplay.pause(); //	Caller tune pause
								callDuration(false); //	Call duration
								setReceiveCallBtn(false); //	Mange receive button
								setMuteCallBtn(false); //	Mange mute button
								//setMergeCallBtn(false); //	Mange merge button
								setHangupCallBtn(false); //	Mange hangup button

								callKeypadSiderProperties('callTermination', 'Termination');	//	On termination manage keypadsider states

								//	history go back
								// history.push(backUrl);	//	history go back

								break;
							case SessionState.Terminating:
								console.log("Call terminating ....");
								//alert("789");
								setStatescreen({
									callScreen: "", //	Hide call screen
									digit: `${state.digitKps}`
								});
								setCallDirection(""); //	Set call direction
								cleanupMedia(); //	Stop media audio control
								callerTuneplay.pause(); //	Caller tune pause
								callDuration(false); //	Call duration
								setReceiveCallBtn(false); //	Mange receive button
								setMuteCallBtn(false); //	Mange mute button
								//setMergeCallBtn(false); //	Mange merge button
								setHangupCallBtn(false); //	Mange hangup button

								callKeypadSiderProperties('callTermination', 'Termination');	//	On termination manage keypadsider states

								break;
							default:
								console.log("Could not identified calling state while calling .... ", callingState);
								//alert("111");
								setStatescreen({
									callScreen: "", //	Hide call screen
									digit: `${state.digitKps}`
								});
								setCallDirection(""); //	Set call direction
								callerTuneplay.pause(); //	Caller tune pause
								callDuration(false); //	Call duration
								setReceiveCallBtn(false); //	Mange receive button
								setMuteCallBtn(false); //	Mange mute button
								//setMergeCallBtn(false); //	Mange merge button
								setHangupCallBtn(false); //	Mange hangup button
			
								//	history go back
								// history.push(backUrl);	//	history go back
			
								break;
						}
					});
			
							// Options including delegate to capture response messages
					const inviteOptions: InviterOptions = {
						requestDelegate: {
							onAccept: (response) => {
								//console.log(response.message);
								//console.log("Positive response ....");
								//console.log(response);
							},
							onReject: (response) => {
								//console.log("Negative response ....");
								//console.log(response);
							},
						},
						sessionDescriptionHandlerOptions: {
							constraints: {
								audio: true,
								video: false,
							},
						},
					};

					//	Send invition
					inviter.invite(inviteOptions).then((request: OutgoingInviteRequest) => {
						console.log("Successfully sent INVITE ....");
						//console.log("INVITE request ....");
						// console.log(request);
					}).catch((error: Error) => {
						console.log("Failed to send INVITE ....");
						// console.log(error);
					});
				//};
				localStorage.setItem('keypadSiderToStatuscard', JSON.stringify(dataForLocalStorageKps));
				break;
			case "directDialKps":
				setStateKps({
					callScreenKps: 'true',	//	Hide call screen
					digitKps: `${state.digitKps}`
				});
				localStorage.setItem('keypadSiderToStatuscard', JSON.stringify(dataForLocalStorageKps));
				break;
			case "setCallTransferOrMergeDestinationKps":
				localStorage.setItem('keypadSiderToStatuscard', JSON.stringify(dataForLocalStorageKps));
				break;
			case "sendDTMFKps":
				localStorage.setItem('keypadSiderToStatuscard', JSON.stringify(dataForLocalStorageKps));
				break;
			default:
				console.log("Keypad default");
				console.log(functionName);
				console.log(argumentOne);
				break;
		}
	}
    };

const digitClickhandler = (event) => {
	console.log(event.target.name);
	if(!state.digitKps) {
		setStateKps({
			digitKps: `${event.target.innerHTML}`
		});
	} else {
		if(event.target.name && event.target.name === 'BackSpace') {
			state.digitKps = state.digitKps.slice(0, -1);
			setStateKps({
				digitKps: `${state.digitKps}`
			});
		} else {
			var old_digit = `${state.digitKps}`;
			if(old_digit === 'undefined'){
				setStateKps({
					digitKps: `${event.target.innerHTML}`
				});
			}else{
				setStateKps({
					digitKps: `${state.digitKps}${event.target.innerHTML}`
				});
			}
		}
	}
};

const removedigit = (event) => {
		state.digitKps = state.digitKps.slice(0, -1);
			setStateKps({
				digitKps: `${state.digitKps}`
			});
}
const onInputChange = (event) => {
	setStateKps({
		digitKps: event.target.value.replace(/[^\d*#]/g,'')
	});
};

const dialNumberKps = () => {
	callStatusCardProperties("dialNumberKps", state.digitKps);	//	Initiate the outgoing call
};



	//	Call keypadsider properties from statuscard component
function callKeypadSiderProperties (functionName = '', argumentOne = '') {
	if(functionName !== '') {
		var dataForLocalStorageSc = {functionName: functionName, argumentOne: argumentOne};
		// console.log("dataForLocalStorageSc ===> ");
		// console.log(dataForLocalStorageSc);

		switch(functionName) {
			case "callTermination":
				localStorage.setItem('statuscardToKeypadSider', JSON.stringify(dataForLocalStorageSc));
				break;
			case "callEstablished":
				localStorage.setItem('statuscardToKeypadSider', JSON.stringify(dataForLocalStorageSc));
				break;
			default:
				console.log("Statuscard to Keypadsider default");
				console.log(functionName);
				console.log(argumentOne);
				break;
		}
	}
};
	//	Call duration
	const callDuration = async (callStatus: Boolean) => {
		if (callStatus === true) {
			//	Call transfer type
			setCallTransferType("Attended");

			var secs = parseInt(0);
			var mins = parseInt(0);

			// setSeconds(secs);
			// setMinutes(mins);

			durationInterval = setInterval(() => {
				// console.log("Seconds" + secs);
				// console.log("minutes" + mins);
				if (secs < 60) {
					secs = parseInt(secs + 1);
					setSeconds(secs);
				}
				if (secs >= 59) {
					secs = parseInt(0);
					mins = parseInt(mins + 1);
					setSeconds(secs);
					setMinutes(mins);
				}
			}, 1000);
			// clearInterval(durationInterval);
		} else {
			//	Call transfer type
			setCallTransferType("Blind");

			setSeconds(parseInt(0));
			setMinutes(parseInt(0));
			clearInterval(durationInterval);
		}
	};


	//	Receive the call
const receiveCall = () => {
		//callerTune.pause(); //	Caller tune pause

		//	Accept incoming call
		if (incomingSession) {
			try {
				incomingSession.accept();
				setReceiveCallBtn(false); //	Mange receive button
				setMuteCallBtn(true); //	Mange mute button
				//setTransferCallBtn(true); //	Mange transfer button
				//setMergeCallBtn(true); //	Mange merge button
				setHangupCallBtn(true); //	Mange hangup button

				//	history go back
				//	history.push("/keypad");	//////////////////////////////////////////

			} catch (error) {
				console.log("Incoming session accept error found - ", error);
			}
		}
	};

	//	Hangup the call
	const hangupCall = () => {
		try {

			//	Manage outgoing state on call hangup
			if ( outgoingSession ) {
				setCallTransferType("Blind");	//	Call transfer type
				if (outgoingSession._state === "Establishing") {
					//alert('123');
					outgoingSession.cancel();

				} else if (outgoingSession._state === "Established") {
					//alert('456');
					outgoingSession.bye();

					
				}

			}
			//	Manage incoming state on call hangup
			if ( incomingSession ) {
				setCallTransferType("Blind");	//	Call transfer type
				if ( incomingSession._state === "Initial" || incomingSession._state === "Establishing" ) {
					// incomingSession.cancel()
					incomingSession.reject();


				} else if (incomingSession._state === "Established") {
					incomingSession.bye();
				}
			}
		} catch (error) {
			console.log("Incoming or Outgoing session not found - ", error);
		}

		//history.goBack();	//	Go back on disconnect
	};


	//	Connect remote media stream
	const setupRemoteMedia = (mediaSession: Session) => {
		const mediaElement = document.getElementById("mediaElement");
		try {
			//	Get all receivers media stream
			console.log("Audio InComing");
			console.log(mediaElement);
			console.log(mediaSession);
			mediaSession.sessionDescriptionHandler.peerConnection
				.getReceivers()
				.forEach((receiver) => {
					if (receiver.track) {
						console.log("Audio remoteStream");

						remoteStream.addTrack(receiver.track);
						console.log(remoteStream);
						mediaElement.srcObject = remoteStream;
						mediaElement.play();
					}
				});
		} catch (error) {
			console.log("Media audio session error - ", error);
		}
	};


	//	Stop media audio
	const cleanupMedia = () => {
		const mediaElement = document.getElementById("mediaElement");
		try {
			if (mediaElement) {
				mediaElement.srcObject = null;
				mediaElement.pause();
			}
		} catch (error) {
			console.log("Clean media audio session error - ", error);
		}
	};

		

		//	Define media stream object
		const remoteStream = new MediaStream();
		
	//	Mute media stream session
	const muteMediaSession = () => {
		const mediaElement = document.getElementById("mediaElement");
		//	Mute outgoing session
		if (mediaElement) {
			//mediaElement.pause();
			setIsMuted(true);
		}

		//	Mute incoming session
		if ( incomingSession && (incomingSession._state === "Initial" || incomingSession._state === "Establishing" || incomingSession._state === "Established") ) {
			if (incomingSession._state === "Established") {
				incomingMediaStream = incomingSession.sessionDescriptionHandler.peerConnection;
				incomingMediaStream.getSenders().forEach((incomingStream: any) => {
					incomingStream.track.enabled = false;
				});
			}
		}

		//	Mute outgoing session
		if ( outgoingSession && (outgoingSession._state === "Establishing" || outgoingSession._state === "Established") ) {
			if (outgoingSession._state === "Established") {
				outgoingMediaStream = outgoingSession.sessionDescriptionHandler.peerConnection;
				outgoingMediaStream.getSenders().forEach((outgoingStream: any) => {
					outgoingStream.track.enabled = false;
				});
			}
		}
	};

	//	Unmute media stream session
	const unMuteMediaSession = () => {
		const mediaElement = document.getElementById("mediaElement");
		//	Unmute outgoing session
		if (mediaElement) {
			mediaElement.play();
			setIsMuted(false);
		}

		//	Unmute incoming session
		if ( incomingSession && (incomingSession._state === "Initial" || incomingSession._state === "Establishing" || incomingSession._state === "Established") ) {
			if (incomingSession._state === "Established") {
				incomingMediaStream = incomingSession.sessionDescriptionHandler.peerConnection;
				incomingMediaStream.getSenders().forEach((incomingStream: any) => {
					incomingStream.track.enabled = true;
				});
			}
		}

		//	Unmute outgoing session
		if ( outgoingSession && (outgoingSession._state === "Establishing" || outgoingSession._state === "Established") ) {
			if (outgoingSession._state === "Established") {
				outgoingMediaStream = outgoingSession.sessionDescriptionHandler.peerConnection;
				outgoingMediaStream.getSenders().forEach((outgoingStream: any) => {
					outgoingStream.track.enabled = true;
				});
			}
		}
	};

	// Handling for incoming INVITE requests
	function onInvite(invitation: Invitation) {
		console.log("INCOMING INCOMING BE READY ========================");
		console.log(invitation);
		
		//	Set caller number
		// console.log(invitation.incomingInviteRequest.earlyDialog.dialogState.remoteURI.normal.user);
		let callerNumber = "Unknown";
		if ( invitation.incomingInviteRequest.earlyDialog.dialogState.remoteURI.normal.user ) {
			callerNumber = invitation.incomingInviteRequest.earlyDialog.dialogState.remoteURI.normal.user;
		}
		setStatescreen({
			callScreen: "true", //	Show call screen
			digit: callerNumber,
		});
		setCallDirection("Incoming from"); //	Set call direction

		setReceiveCallBtn(true); //	Mange receive button
		//setTransferCallBtn(true); //	Mange transfer button
		setHangupCallBtn(true); //	Mange hangup button
		callerTuneplay.play(); //	Caller tune play
		// Setup incoming session delegate
		invitation.delegate = {
			// Handle incoming REFER request.
			onRefer(referral: Referral) {
				//console.log("Handle incoming REFER request.");
				referral.accept().then(() => {
					referral.makeInviter().invite();
				});
			},
		};

		// An Invitation is a Session
		incomingSession = invitation;

		//////////////////////////////////////////
		// //	Keypad data array
		// var keypadDataParams = {
		// 	callScreen: "true",
		// 	digit: state.digit,
		// 	receiveCallBtn: false, //	Mange receive button
		// 	muteCallBtn: true, //	Mange mute button
		// 	transferCallBtn: true, //	Mange transfer button
		// 	mergeCallBtn: true, //	Mange merge button
		// 	callType: "Incoming", //	Mange call type
		// 	backUrl: backUrl, //	Mange back url
		// 	incomingSession: incomingSession, //	Mange incomingSession
		// 	outgoingSession: "", //	Mange outgoingSession
		// }
		// //	Send update keypad data
		// props.updateKeypadData(keypadDataParams);
		//////////////////////////////////////////


		// Handle incoming session state changes.
		invitation.stateChange.addListener((incomingState: SessionState) => {
			// console.log(incomingState);
			switch (incomingState) {
				case SessionState.Initial:
					console.log("Incoming initiated ....");
					callerTuneplay.play(); //	Caller tune play
					break;
				case SessionState.Establishing:
					console.log("Incoming establishing ....");
					// console.log("Call incomingSession INVITE ....");
					// 			console.log(incomingSession);

					// 			console.log("Call outgoingSession INVITE....");
					// 			console.log(outgoingSession);
					callerTuneplay.play(); //	Caller tune play
					break;
				case SessionState.Established:
					console.log("Incoming established ....");
					// console.log("Call incomingSession INVITE....");
					// 			console.log(incomingSession);

					// 			console.log("Call outgoingSession INVITE....");
					// 			console.log(outgoingSession);
					callerTuneplay.pause(); //	Caller tune play
					setCallDirection("Connected with"); //	Set call direction
					setupRemoteMedia(invitation); //	Media audio control
					callDuration(true); //	Call duration
					callKeypadSiderProperties('callEstablished', 'Established');	//	On answer manage keypadsider states
					break;
				case SessionState.Terminated:
					console.log("Incoming terminated ....");
					setStatescreen({
						callScreen: "", //	Show call screen
						digit: ""
					});
					setCallDirection(""); //	Set call direction
					cleanupMedia(); //	Stop media audio control

					callerTuneplay.pause(); //	Caller tune pause
					callDuration(false); //	Call duration

					setMuteCallBtn(false); //	Mange mute button
					//setTransferCallBtn(false); //	Mange transfer button
					//setMergeCallBtn(false); //	Mange merge button

					callKeypadSiderProperties('callTermination', 'Termination');	//	On termination manage keypadsider states

					//	history go back
					// history.push(backUrl);	//////////////////////////////////////////

					break;
				case SessionState.Terminating:
					console.log("Incoming terminating ....");
					setStatescreen({
						callScreen: "", //	Show call screen
						digit: "",
					});
					setCallDirection(""); //	Set call direction
					cleanupMedia(); //	Stop media audio control
					callerTuneplay.pause(); //	Caller tune pause
					callDuration(false); //	Call duration

					setMuteCallBtn(false); //	Mange mute button
					//setTransferCallBtn(false); //	Mange transfer button
					//setMergeCallBtn(false); //	Mange merge button

					callKeypadSiderProperties('callTermination', 'Termination');	//	On termination manage keypadsider states

					break;
				default:
					console.log("Could not identified incoming state .... ", incomingState);
					setStatescreen({
						callScreen: "", //	Show call screen
						digit: "",
					});
					setCallDirection(""); //	Set call direction
					callerTuneplay.pause(); //	Caller tune pause
					callDuration(false); //	Call duration

					setMuteCallBtn(false); //	Mange mute button
					//setTransferCallBtn(false); //	Mange transfer button
					//setMergeCallBtn(false); //	Mange merge button

					//	history go back
					// history.push(backUrl);	//////////////////////////////////////////

					break;
			}
		});

		// Handle incoming INVITE request.
		let constrainsDefault: MediaStreamConstraints = {
			audio: true,
			video: false,
		};

		//	Handle invitation accept options
		const options: InvitationAcceptOptions = {
			sessionDescriptionHandlerOptions: {
				constraints: constrainsDefault,
			},
		};

		//	Make incoming call in progress untill incoming call is accepted
		invitation.progress(options);
	}
const logoutwebphone = (event) => {
		localStorage.setItem("authenticated", false);
		localStorage.setItem("logout", true);

        window.location.href = '/login';
}

return (
<div>
	<div class="reset" align="right"  style={{marginRight:"30px",cursor:"pointer"}}>

    			<img src={Logout} alt="Logout" width="30px" onClick={(e) => logoutwebphone(e)} />

        </div>
    <div class="container">
		
	{/* <button onClick={() => unregister()}>Unregistered</button> */}
    <div class="header">

        <div class="input" align="right">
			<input className={"form-control keypad-control"} type="text" maxLength = "16" readOnly={(state.callScreenKps === 'true') ? 'readonly' : ''} autoFocus value = {(state?.digitKps && state?.digitKps !== '' && state?.digitKps !== 'undefined') ? state?.digitKps : ''} onChange = {(e) => onInputChange(e)} aria-label="Search" />
        </div>

        <div class="reset" align="left">
            
		<IconButton name="details" onClick={( e ) => removedigit( e )}>
       			<BackspaceIcon />
    	</IconButton>
        </div>

    </div>

	<div>
			<div>
				{/* Media element - Audio control */}
				<audio id="mediaElement" controls style={{ display: "none"}} ></audio>
				

				{/* Incoming and outgoing call screen */}
				<Card className={"user-detail-side-card " + ((statescreen.callScreen === 'true') ? 'display-block' : 'display-none')}>
				{/* <Card className={"user-detail-side-card " + ((state.callScreen === 'true') ? 'display-none' : 'display-block')}> */}
					<div className={"d-lg-flex col-12 py-0 px-0"}>
						<div className={"media-body incoming-call-detail out-going-call-details pt-2 px-2 text-centre"}>
								<div className={"col-12 keypad_item"}>
									<Typography className={"mt-0 profile-detail-text mt-2 mb-2 text-white profile-text-size"}>{ callDirection } .... { statescreen.digit || "Unknown" }</Typography>
									<Typography className={"text-white"}> {minutes < 10 ?  `0${minutes}` : minutes} : {seconds < 10 ?  `0${seconds}` : seconds} </Typography>
								</div>
								<div className={"col-12 ml-auto mt-3 favourite_contact"}>
								<div direction="row" style={{display:'flex',justifyContent:'center'}}>
									{/* Mute and unmute */}
									<div style={{marginRight:"10px" ,cursor:"pointer"}}>									<Typography style={{display: muteCallBtn === true ? 'block' : 'none' }} className={"mr-1 mb-0 " + ((muteCallBtn === true) ? 'block' : 'none')} onClick={() => (isMuted === false) ? muteMediaSession() : unMuteMediaSession()} alt="MuteUnmute">
										<img src={(isMuted === false) ? Mute : Unmute} alt="MuteUnmute" width="30px" />
									</Typography></div>
									{/* Receive call */}
									<div style={{marginRight:"10px" ,cursor:"pointer"}}>									<Typography style={{display: receiveCallBtn === true ? 'block' : 'none' }} className={"mr-1 mb-0 " + ((receiveCallBtn === true) ? 'action-button-display-add' : 'action-button-display-none')} onClick={() => receiveCall(true)} alt="CallReceive">
										<img src={CallReceive} alt="CallReceive" width="30px" />
									</Typography></div>
									{/* Hangup the call */}
									<div style={{cursor:"pointer"}}>
									<Typography style={{display: hangupCallBtn === true ? 'block' : 'none' }} className={"mr-1 mb-0 " + ((hangupCallBtn === true) ? 'action-button-display-add' : 'action-button-display-none')} onClick={() => hangupCall()} alt="CallHangup">
										<img src={CallHangup} alt="CallHangup" width="30px" />
									</Typography></div>
								</div>
							</div>
						</div>
					</div>
					
				</Card>

				
			</div>
		</div>

    <div class="body">
        <div class="row">
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >1</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >2</button>
            </div>
            <div class="col-3">
            	<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >3</button>
            </div>
        </div>

        <div class="row">
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >4</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >5</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >6</button>
            </div>
        </div>	

        <div class="row">
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >7</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >8</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >9</button>
            </div>
        </div>	

        <div class="row">
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >*</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >0</button>
            </div>
            <div class="col-3">
				<button style={{cursor:"pointer"}} onClick={ (e) => (state.callScreenKps === 'true') ? sendDTMFKps(e) : digitClickhandler(e) } >#</button>
            </div>
        </div>		


        <div class="row" align="center">
            <div class="col-12" align="center">
                <button style={{cursor:"pointer"}} id="call" onClick={() => dialNumberKps()}>
                 <i><CallIcon/></i>
                </button>
            </div>
        </div>
    
    </div>

</div> 
</div>
);
}
export default Webphone