import React, { useState, useEffect } from 'react'
import { Grid,Paper, Avatar, TextField, Button} from '@material-ui/core'
import DialerSipIcon from '@material-ui/icons/DialerSip';
import { Registerer, RegistererState, UserAgent } from "sip.js";
import Alert from '@material-ui/lab/Alert';
import { useNavigate } from "react-router-dom";
import { WSS_URL } from '../constant';

const Login=()=>{

	let userAgent: any;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [domain, setDomain] = useState('');
    const [state = { digit: '', callScreen: '' }, setState] = useState([]);
    const [sipStatus, setSipStatus] = useState('');
    const paperStyle={padding :20,height:'60vh',width:400, margin:"150px auto"}
    const avatarStyle={backgroundColor:'#303f9f'}
    const btnstyle={margin:'30px 0'}
	const errorstyle={margin:'10px 0'}
	const display={display:'none'}
	const notdisplay={display:'block'}
	const navigate = useNavigate();

	useEffect(() => {

		var authenticate = localStorage.getItem("authenticated");
		var logout = localStorage.getItem("logout");
		var url = window.location.pathname;


		if(authenticate === 'true') {
			window.location.href = '/webphone';

		}else if(authenticate === 'false' && logout !== 'true'){
			localStorage.setItem("authenticated", '');	
			window.location.href = '/login';
		}

	}, []);
    
    const handleSubmit = e => {
        e.preventDefault();
        
        var UAURI = UserAgent.makeURI("sip:" + username + "@" + domain);
		if (!UAURI) {
			throw new Error("Failed to create UserAgent URI ....");
		}
		
        console.log(UAURI);
        const userOptions: UserAgentOptions = {
			uri: UAURI,
			authorizationPassword: password,
			authorizationUsername: username,
			transportOptions: {
				server: WSS_URL,
				traceSip: true,
			},
			register: true,
			noAnswerTimeout: 60,
			userAgentString: "ASTPP | WEBRTC ",
			dtmfType: "info",
			displayName: "WEBRTC",
			activeAfterTransfer: false, //	Die when the transfer is completed
			logBuiltinEnabled: false, //	Boolean - true or false - If true throws console logs
		};
        console.log(userOptions);
        userAgent = new UserAgent(userOptions);
        userAgent.start().then(() => {
			console.log("Connected ....");
            console.log(userAgent);
			//	Create register object
			const registerer = new Registerer(userAgent);
            
			registerer.stateChange.addListener(
				(registrationState: RegistererState) => {
			
					switch (registrationState) {
						case RegistererState.Registered:
							console.log("Registered ....");
							localStorage.setItem('username', username);
							localStorage.setItem('password', password);
							localStorage.setItem('domain', domain);
							localStorage.setItem("authenticated", true);
							setSipStatus(true);
							window.setTimeout( function () {
								window.location.href = '/webphone';
							}, 2000 );
							break;
						case RegistererState.Unregistered:
							console.log("Unregistered ....");
							setSipStatus(false);
							break;
						case RegistererState.Terminated:
							console.log("Terminated ....");
							setSipStatus(false);
							break;
						default:
							console.log("Could not identified registration state .... ", registrationState);
							setSipStatus(false);
							break;
					}
				}
			);
            
            if (state.callScreen !== "true") {
				registerer.register().then((request) => {
					// console.log("Successfully sent REGISTER request .... ", request);
				}).catch((error) => {
					// console.log("Failed to send REGISTER request .... ", error);
				});
			}
		}).catch((error) => {
			console.log("Failed to connect user agent .... ", error);
		});
    };

    return(
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                     <Avatar style={avatarStyle}><DialerSipIcon/></Avatar>
                    <h2>SIP Details</h2>
                </Grid>
                <form noValidate onSubmit={handleSubmit}>

                <TextField label='Username' name='username' placeholder='Enter username' onChange={event => setUsername(event.target.value)}
          value={username} fullWidth required/>
                <TextField label='Password' placeholder='Enter password' type='password' onChange={event => setPassword(event.target.value)}
          value={password} fullWidth required/>
                <TextField label='Domain' placeholder='Enter domain' type='domain' onChange={event => setDomain(event.target.value)}
          value={domain} fullWidth required/>
		  	 {/* <div className={( ( sipStatus === true ) ? 'display-block' : 'display-none' )}> */}
			 <div style={{display: sipStatus === true ? 'block' : 'none' }}>

		     	<Alert severity="success" style={errorstyle}>Registered</Alert>
			 </div>
			 <div style={{display: sipStatus === false ? 'block' : 'none' }}>
		     	<Alert severity="error" style={errorstyle}>Invalid Credentials</Alert>
			 </div>
			 {/* <div style={{display: sipStatus === '' ? 'block' : 'none' }}>
			 <TextField style={btnstyle}/>
			 </div>
			  */}
             <Button type="submit" color='primary' variant="contained" style={btnstyle} fullWidth>Register</Button>
                </form>
            </Paper>
        </Grid>
    )
}

export default Login
